.print_word table  th {
  border: 1px solid black;
}
.print_word table  td {
  border: 1px solid black;
}

.print_word p  {
  line-height: 120%;
}
