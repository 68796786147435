@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #00c2cb !important;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container .wrapper {
  position: relative;
  height: 220px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  border: 2px dashed #c2cdda;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper .image {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wrapper .text {
  font-size: 20px;
  font-weight: 500;
  color: #38b2ac;
}

.wrapper #cancel-btn {
  position: absolute;
  font-size: 50px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 10;
  color: white;
  display: none;
}

.image:hover #cancel-btn {
  display: block;
}

.wrapper .file-name {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #fff;
  padding: 8px 0;
  font-size: 18px;
  display: none;
  background: linear-gradient(
    135deg,
    rgba(42, 214, 120, 0.856) 0%,
    #38b2ac 100%
  );
}

#custom-btn {
  margin: 20px auto 0;
  width: 75%;
  height: 40px;
  display: block;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  outline: none;
  letter-spacing: 1px;
  cursor: pointer;
  background: #38b2ac;
}

.page-content {
  /* margin-top: 100px; */
  font-size: 18pt;
}

.logo {
  font-family: "Caveat", cursive;
  font-size: 56pt important!;
  font-weight: 900;
}

.dropdown-item:hover {
  color: #fff !important;
}

.modal {
  background-color: rgba(0, 0, 0, 0.7);
}

/* table */

table.report-container {
  page-break-after: always;
}


thead.report-header {
  display: table-header-group;

}

tfoot.report-footer {
  display: table-footer-group;

}

table.report-container .box {
  page-break-inside: avoid;
}

@media print {
  thead img {
      page-break-inside: auto !important;
  }

  thead.report-header {
      display: table-header-group;

  }
}

